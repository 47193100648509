<template>
  <div class="GlobalFormDemo">
    <GlobalForm class="search-form"  :needBtnLoading="true" :init-data="initData" :form-rules="formRules" :form-item-list="formItemList"
      :inline="true" round :labelWidth="80" @handleConfirm="handleConfirm" confirmBtnName="查询">
      <el-form-item class="el_from" label="出库单位" slot="operationSelect" style="margin: 0">
        <el-select v-model="outSupplierId" placeholder="请选择出库单位" filterable clearable :filter-method="warehouseFilter"  @visible-change="visibleyouchange">
          <el-option v-for="item in channelSelectList" :key="item.id" :label="item.exchangeUnitName"
            :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="出库单据" slot="operationSelect1" style="margin: 0">
        <el-input :clearable="true" v-model="outNo" placeholder="请输入出库单据"></el-input>
      </el-form-item>
      <el-form-item class="el_from" label="提货方式" slot="operationSelect2" style="margin: 0">
        <el-select v-model="deliveryType" placeholder="请选择提货方式" filterable clearable>
          <el-option v-for="item in companySelectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="制单人" slot="orderPlole3" style="margin: 0">
        <el-select filterable v-model="userId" placeholder="请选择制单人" clearable>
          <el-option v-for="item in operatorList" :key="item.id" :label="item.staffName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_from" label="出库备注" slot="outRemark" style="margin: 0">
        <el-input :clearable="true" v-model="outRemark" placeholder="请输入出库备注"></el-input>
      </el-form-item>
      <el-form-item class="el_from" label="制单时间" slot="operationSelect3" style="margin: 0">
        <el-date-picker style="margin-left: 10px;" v-model="entryTime" :picker-options="pickerOptions" size="small"
              type="daterange" value-format="timestamp" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期"  :default-time="['00:00:00', '23:59:59']" >
            </el-date-picker>
      </el-form-item>
    </GlobalForm>
    <div style="text-align: right;margin-bottom: 20px" slot="AddSlot">
      <!-- <el-button size="small" type="primary" icon="el-icon-plus" @click="addMatchm">创建出库单</el-button> -->
      <el-button :loading="Excelloading" icon="el-icon-download" size="small" type="success" @click="exportExcel">导出Excel</el-button>
      <el-button :loading="imeiloading" icon="el-icon-download" size="small" type="warning" @click="exportnumberExcel">导出串号明细</el-button>
    </div>
    <el-dialog title="删除提示" :visible.sync="dialogDelVisible" :close-on-click-modal="false" width="380px"
      @closed="dialogDelVisible = false">
      <div class="del-title">
        <div class="sure_tip">确认要删除该数据吗？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogDelVisible = false">取消</el-button>
        <el-button type="primary" @click.native="handleSuccessDel">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="安全验证" :visible.sync="commandDisableShow" :close-on-click-modal="false" width="500px"
      @closed="commandDialogClosed">
      <div class="command">
        <div style="width: max-content">谷歌验证码：</div>
        <el-input v-model="modelform.command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandDisableShow = false">取消</el-button>
        <el-button type="primary" @click.native="commandDisableSure" :loading="butLoading">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { http } from "@/utils/request/newIndex";
import moment from "moment";
import { MessageBox } from "element-ui";

let checkPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的手机号码"));
  } else {
    callback();
  }
};
let checkPrice = (rule, value, callback) => {
  if (value < 50) {
    callback(new Error("扣款条件不能小于50元"));
  } else {
    callback();
  }
};
export default {
  name: "Form",
  data() {
    return {
      Excelloading:false,
      imeiloading:false,
      day60: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate && this.pickerMinDate) {
            this.pickerMinDate = null;
          } else if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            return (
              time.getTime() > this.pickerMinDate + this.day60 ||
              time.getTime() < this.pickerMinDate - this.day60
            );
          }
          return false;
        },
      },
      outSupplierId:"",
      operatorList: [],
      channelSelectList: [],
      filtterSelectList: [],
      initData: null,
      dialogVisible: false,
      matchChannelId: "",
      merchantId: "",
      companyId: "",
      opname: "",
      deliveryType:"",
      userId:"",
      outRemark:"",
      entryTime:  [ new Date(moment().subtract(30, 'day').startOf('day').format("YYYY-MM-DD")+" "+'00:00:00'),
      new Date( moment().endOf('day').format("YYYY-MM-DD")+' '+"23:59:59")],
      companySelectList: [{
        name:"自提",
        id:"01"
      },{
        name:"邮寄",
        id:"02"
      }],
      companyMatchSelectList: [],
      allMerchantSelectList: [],
      formItemList: [{ slotName: "operationSelect" }, { slotName: "operationSelect1" }, { slotName: "operationSelect2" }, { slotName: "orderPlole3" }, { slotName: "outRemark" }, { slotName: "operationSelect3" }],
      modelform: {
        matchChannelId: "",
        merchantId: "",
        companyId: "",
        takeEffectTime: "",
        command: "",
      },
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now(); //禁止选择今天和今天之前的时间
      //   },
      // },
      SeachParams: {},
      formRules: {},
      modelrules: {
        matchChannelId: [
          {
            required: true,
            message: "请选择撮合渠道",
            trigger: ["blur", "change"],
          },
        ],
        merchantId: [
          {
            required: true,
            message: "请选择回收商家",
            trigger: ["blur", "change"],
          },
        ],
        companyId: [
          {
            required: true,
            message: "请选择门店商家",
            trigger: ["blur", "change"],
          },
        ],
        btstarsTax: [
          { required: true, message: "请输入繁星扣款", trigger: "blur" },
        ],
        takeEffectPrice: [
          { required: true, message: "请输入扣款条件", trigger: "blur" },
          { type: "number", validator: checkPrice, trigger: "blur" },
        ],
        takeEffectTime: [
          {
            required: true,
            message: "请选择生效时间",
            trigger: ["blur", "change"],
          },
        ],
      },
      butLoading: false,
      outNo:"",
      userName:"",
      middleRegionVos: [],
      deductionColumns: [
        { slotName: "rank" },
        { label: "渠道扣款(次/收货次数)", prop: "channelTax" },
        { label: "繁星扣款(次/收货次数)", prop: "btstarsTax" },
        { slotName: "operation" },
      ],
      middleRegionRules: {
        lower: [
          { required: true, message: "请输入报价区间起始值", trigger: "blur" },
        ],
        upper: [
          { required: true, message: "请输入报价区间结束值", trigger: "blur" },
        ],
        channelTax: [
          { required: true, message: "请输入渠道扣款", trigger: "blur" },
        ],
        btstarsTax: [
          { required: true, message: "请输入繁星扣款", trigger: "blur" },
        ],
      },
      rowId: "",
      middleRegion: {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      },
      dialogMiddleRegionTitle: "",
      dialogMiddleRegionVisible: false,
      dialogDelVisible: false,
      commandDisableShow: false,
    };
  },
  created() {
    this.handleConfirm();
    this.getpullDown();
    this.operatorSelect()
  },
  watch: {
    "modelform.matchChannelId"(val) {
      this.modelform.companyId = "";
      this.modelform.merchantId = "";

    },
    "modelform.companyId"(val) {
      this.modelform.merchantId = "";
    },
  },
  methods: {
     // 获取操作人下拉
     operatorSelect() {
      _api.operatorSelect({pageNum:1,pageSize: 999,merchantId:this.$route.query.merchantId}).then((res) => {
        if (res.code == 1) {
          this.operatorList = res.data;
        }
      });
    },
    getpullDown() {
      http.pullDown({merchantId:this.$route.query.merchantId}).then(res => {
        console.log('$$$',res);
        this.channelSelectList = JSON.parse(JSON.stringify(res)).data
        this.filtterSelectList = JSON.parse(JSON.stringify(res)).data
      })
    },
    visibleyouchange(e) {
      if (e) {
        this.channelSelectList = this.filtterSelectList;
      }
    },
      //出库单位筛选
      warehouseFilter(val) {
      console.log(val);
      // this.value = val //给绑定值赋值
      if (val) { //val存在
        this.channelSelectList = this.filtterSelectList.filter((item) => {
          if (item.exchangeUnitName.indexOf(val) !==-1 || item.exchangeShortName.indexOf(val) !== -1 || item.exchangeShortName.toUpperCase().indexOf(val.toUpperCase()) !== -1) {
            return true
          }
        })
      } else { //val为空时，还原数组
        this.channelSelectList = this.filtterSelectList;
      }
    },
    moneyInput(obj, prop, value) {
      value = "" + value.replace(/[^0-9]/g, "");
      if (!value) {
        this.$set(obj, prop, "");
        return;
      }
      value = parseInt(value);
      this.$set(obj, prop, value);
    },
    handleConfirm(data,cd) {
      if (this.entryTime==null) {
        (this.startTime = ""), (this.endTime = "");
      } else {
        (this.startTime = moment(this.entryTime[0]).format("x")),
          (this.endTime = moment(this.entryTime[1]).format("x"));
      }
      console.log(this.startTime);
      console.log(this.endTime);
      const SeachParams = {
        deliveryType: this.deliveryType,
        endTime: this.endTime,
        outNo: this.outNo,
        outSupplierId: this.outSupplierId,
        startTime:this.startTime,
        userId: this.userId,
        outRemark:this.outRemark,
        userName: "",
        warehouseId: '',
        pageNum: 1,
        pageSize: 10,
        merchantId:this.$route.query.merchantId,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      this.SeachParams = SeachParams;
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    addMatchm() {
      // this.dialogVisible = true;
      this.$router.push({path:"/outgoManagement/createWarehouse"})
    },
    handleDialogClosed() {
      this.$refs["form"].resetFields();
      this.companyMatchSelectList = []
      this.allMerchantSelectList = []
      this.dialogVisible = false;
    },
    // 确认添加
    handleSuccess() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.commandDisableShow = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //门店商家下拉列表（撮合交易）
    getSelectListMatch() {
      _api
        .getSelectListMatch({ matchId: this.modelform.matchChannelId })
        .then((res) => {
          if (res.code === 1) {
            this.companyMatchSelectList = res.data;
          }
        });
    },
    // 已合作回收商家下拉列表
    getMiddleAdoptMerchantMatch() {
      _api
        .getMiddleAdoptMerchantMatch({
          companyId: this.modelform.companyId,
          matchId: this.modelform.matchChannelId,
        })
        .then((res) => {
          if (res.code === 1) {
            this.allMerchantSelectList = res.data;
          }
        });
    },
    // 添加生效时间时间
    completionTimeFun(e) {
      console.log(e);
    },
    // 选择回收商家
    handleChange(val) {
      this.merchantId = val;
    },
    // 选择撮合渠道回调
    formMatchChange(e) {
      console.log(e);
      this.getSelectListMatch();
    },
    // 选择门店商家回调
    formCompanyChange(e) {
      console.log(e);
      this.getMiddleAdoptMerchantMatch();
    },
    //导出串号明细
    exportnumberExcel(e) {
      this.imeiloading = true;
      http.excelImeiAll({merchantId:this.$route.query.merchantId,...this.SeachParams}).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            const fileName = "出库串号明细.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
        this.imeiloading = false;
      }).catch(err=>{
        this.imeiloading=false
      });
    },
    exportExcel(e) {
      this.Excelloading = true;
      http.outwarehouseExcel({merchantId:this.$route.query.merchantId,...this.SeachParams}).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            const fileName = "出库单据明细.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
        this.Excelloading = false;
      }).catch(err=>{
        this.Excelloading = false;
      });
    },
    // 添加扣款规则
    addRule() {
      this.tableOperation = "add";
      this.rowId = "";
      this.dialogMiddleRegionTitle = "添加规则-撮合扣款";
      this.dialogMiddleRegionVisible = true;
    },
    // 扣款条件表格操作
    handleBtn(row, type, index) {
      this.rowId = index;
      this.tableOperation = type;
      if (type == "edit") {
        this.dialogMiddleRegionTitle = "编辑规则-撮合扣款";
        this.middleRegion.lower = row.lower;
        this.middleRegion.upper = row.upper;
        this.middleRegion.btstarsTax = row.btstarsTax;
        this.middleRegion.channelTax = row.channelTax;
        this.dialogMiddleRegionVisible = true;
      } else {
        this.dialogDelVisible = true;
      }
    },
    middleRegionDialogClosed() {
      this.middleRegion = {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      };
      this.$refs["middleRegion"].resetFields();
    },
    // 添加规则-撮合扣款弹框确定
    middleRegHandleSuccess() {
      this.$refs["middleRegion"].validate((valid) => {
        if (valid) {
          let middleRegion = this.middleRegion;
          if (Number(middleRegion.lower) > Number(middleRegion.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(middleRegion.lower) == Number(middleRegion.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            lower: middleRegion.lower,
            upper: middleRegion.upper,
          };
          if (this.middleRegionVos.length && this.tableOperation != "edit") {
            let numArr = [];
            this.middleRegionVos.forEach((item) => {
              numArr.push(item.lower);
              numArr.push(item.upper);
            });
            let iMin = Math.min(...numArr); //最小值
            let iMAX = Math.max(...numArr); //最大值
            if (middleRegion.upper != iMin && middleRegion.lower != iMAX) {
              this.$message.error("价位区间不连续");
              return;
            }
            if (middleRegion.lower != iMAX && middleRegion.upper != iMin) {
              this.$message.error("价位区间不连续");
              return;
            }
          }
          params.btstarsTax = middleRegion.btstarsTax;
          params.channelTax = middleRegion.channelTax;
          if (this.rowId.toString()) {
            this.middleRegionVos.splice(this.rowId, 1, params);
          } else {
            this.middleRegionVos.push(params);
          }
          this.dialogMiddleRegionVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除弹框确认
    handleSuccessDel() {
      this.middleRegionVos.splice(this.rowId, 1);
      this.dialogDelVisible = false;
    },
    commandDialogClosed() {
      this.modelform.command = "";
    },
    // 谷歌验证弹框确定
    commandDisableSure() {
      let params = {
        middleRegionVos: this.middleRegionVos,
        ...this.modelform,
      };
      this.butLoading = true;
      _api
        .addMatchTransaction(params)
        .then((res) => {
          this.$message.success("添加成功");
          this.dialogVisible = false;
          this.commandDisableShow = false;
          this.handleConfirm(this.SeachParams);
          this.butLoading = false;
          this.middleRegionVos = [];
        })
        .catch(() => {
          this.butLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo {
  /deep/ .el-form--inline .el-form-item {
    margin-right: 20px !important;
  }

  .search-form {
    /deep/.el-select>.el-input {
      width: 170px !important;
    }
  }

  .add-form {
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }

    /deep/.el-input-group__prepend {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }

    /deep/.el-select {
      width: 100%;
    }
  }

  .rules-form {
    .rule-title {
      display: flex;
      align-items: center;
      color: #ff8080;
      margin-bottom: 20px;
    }

    .tip_ico {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }

  .del-title {
    color: #ff8080;
    font-size: 12px;

    .sure_tip {
      margin-top: 15px;
      font-size: 16px;
      color: #333333;
    }
  }

  .command {
    display: flex;
    align-items: center;

    /deep/.el-input {
      width: 300px;
    }
  }
}
</style>
