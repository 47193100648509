<template>
  <div class="GlobalTable">
    <GlobalTable ref="GlobalTable" v-loading="loading" :columns="tableColumns" :data="seachDataList"
      :currentPage="page.pageNum" :total="page.total" @handleCurrentChange="handleCurrentChange">
      <el-table-column label="出库旧机" slot="outMachineCount" align="center"  width="100px">
        <template slot-scope="{ row }">
          <div>{{ row.outMachineCount ? row.outMachineCount + '台' : "--" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="提货方式" slot="deliveryType" align="center" width="100px">
        <template slot-scope="{ row }">
          <div>
            {{ row.deliveryType == "01" ? "自提" : row.deliveryType == "02" ? "邮寄" : "--" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="自提凭证/快递单号" slot="sendExpressNo" align="center"  width="150px">
        <template slot-scope="{ row }">
          <div v-if="row.sendExpressNo">{{ row.sendExpressNo }}</div>
          <img v-if="row.selfProof" @click="seeSampleImage(row)" style="width:40px;height: 40px;" :src="row.selfProof"
            alt="" />
          <div v-if="!row.selfProof && !row.sendExpressNo">--</div>
        </template>
      </el-table-column>
      <el-table-column label="出库备注" slot="outRemark" align="center">
        <template slot-scope="{ row }">
          <div>{{ row.outRemark || "--" }}</div>
        </template>
      </el-table-column>

      <el-table-column label="操作" slot="operation" align="center">
        <template slot-scope="{ row }">
          <!-- 启用 -->
          <div style=" display: inline-block;margin-right: 5px;">
            <el-button v-if="row.state != '02'" size="mini" type="text" @click="jumDetal(row)">查看详情
            </el-button>
          </div>
          <!-- 禁用 -->
          <!-- <div class="deltyu">
            <el-button size="mini" v-if="row.state != '02'" type="text" @click="outbleopen(row)">打印单据
            </el-button>
          </div> -->
          <!-- <div class="sentyu">
            <el-button size="mini" v-if="!row.deliveryType" type="text" @click="editBtn(row)">取货方式
            </el-button>
          </div> -->
           <!-- 导出串号-->
           <div style=" display: inline-block;margin-right: 5px;">
            <el-button v-if="row.state != '02'" size="mini" type="text" @click="Derived(row)">导出串号
            </el-button>
          </div>
        </template>
      </el-table-column>
    </GlobalTable>
    <!-- 动态口令弹出框 -->
    <el-dialog :title="commandTitle" :visible.sync="commandShow" :close-on-click-modal="false" width="500px"
      @closed="commandClosed">
      <div class="enable-tip">
        <div>
          说明：关闭后不再向该回收商进行撮合交易扣款，包括渠道扣款和繁星扣款。
        </div>
        <div class="tip-sure">关闭后不可重新开启，是否确认关闭？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="submit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="删除提示" :visible.sync="dialogDelVisible" :close-on-click-modal="false" width="380px"
      @closed="dialogDelVisible = false">
      <div class="del-title">
        <div class="sure_tip">确认要删除该数据吗？</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="dialogDelVisible = false">取消</el-button>
        <el-button type="primary" @click.native="handleSuccessDel">确定</el-button>
      </span>
    </el-dialog>
    <!-- 打印提示 -->
    <el-dialog title="打印提示" :visible.sync="commandDisableShow" :close-on-click-modal="false" width="350px"
      @closed="commandDialogClosed">
      <div class="command2">
        <div class="sure_tip" style="margin-bottom: 20px;">是否确认打印？</div>
        <div class="sure_tip">
          <span> 选择打印份数：</span>
          <el-input-number size="small" v-model="prnitnum" :min="1" :max="2"></el-input-number>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandDisableShow = false">取消</el-button>
        <el-button type="primary" @click.native="commandDisableSure" :loading="butLoading">确定</el-button>
      </span>
    </el-dialog>
    <!--提货方式弹框-->
    <el-dialog class="add-form" title="选择取货方式" :visible.sync="addShow" :close-on-click-modal="false" width="500px"
      @closed="addDialogClose">
      <el-form :model="formDataDialog" :rules="formRulesDialog" ref="ruleForm" label-width="106px">
        <el-form-item label="选择取货方式" required>
          <el-radio-group v-model="formDataDialog.deliveryType">
            <el-radio label="01">自提</el-radio>
            <el-radio label="02">邮寄</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="邮寄快递单号" v-if="formDataDialog.deliveryType == '02'" prop="sendExpressNo">
          <el-input type="textarea" v-model="formDataDialog.sendExpressNo"></el-input>
        </el-form-item>
        <el-form-item label="上传自提凭证" v-else>
          <ElImgUpload :exist-image="formDataDialog.selfProof" @handleDelete="handleUploadDelete"
            @handleChange="handleUploadChange($event)" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addShow = false">取 消</el-button>
        <el-button size="small" type="primary" :loading="btnLoading" @click="submitAdd">确认</el-button>
      </span>
    </el-dialog>
    <el-image-viewer v-if="showViewer" :on-close="
      () => {
        showViewer = false;
      }
    " :url-list="imgList" />
    <!-- 打印出库单 -->
    <MimeoGraph ref="printView" :detaleDatax="detaleData" :coPies="prnitnum"></MimeoGraph>
  </div>
</template>

<script>
/* eslint-disable */
import _api from "@/utils/request";
import { http } from "@/utils/request/newIndex";
import { getLodop } from "@/utils/LodopFuncs";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import ElImgUpload from "../../../components/global/components/elements/upload-element.vue";
export default {
  name: "Table",
  components: { ElImgUpload, ElImageViewer },
  data() {
    return {
      imgList: [],
      showViewer: false,
      btnLoading: false,
      commandDisableShow: false,
      prnitnum: 1,
      formDataDialog: {
        "deliveryType": "01",
        "selfProof": "",
        "sendExpressNo": ""
      },
      formRulesDialog: {
        sendExpressNo: [
          {
            required: true,
            trigger: "blur",
            message: "邮寄快递单号不能为空",
          },
        ],
      },
      addShow: false,
      loading: false,
      seachDataList: [],
      commandShow: false,
      butLoading: false,
      commandTitle: "",
      command: "",
      matchId: "",
      dialogVisible: false,
      takeEffectTimeIsEdit: false,
      rowId: "",
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      pageNum: 1,
      companySelectList: [],
      alreadyCompanyList: [],
      tableColumns: [
        { label: "出库单据", prop: "outNo" },
        { slotName: "outMachineCount" },
        { label: "出库单位", prop: "outSupplierName" },
        { label: "制单人", prop: "outUserName" , width:"100px"},
        { label: "制单时间", prop: "outTime" },
        { slotName: "deliveryType" },
        { slotName: "sendExpressNo" },
        { slotName: "outRemark" },
        { slotName: "operation" },
      ],
      matchChannelName: "",
      merchantName: "",
      companyName: "",
      modelform: {
        matchChannelId: "",
        merchantId: "",
        companyId: "",
        takeEffectTime: "",
        command: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //禁止选择今天和今天之前的时间
        },
      },
      modelrules: {
        takeEffectTime: [
          {
            required: true,
            message: "请选择生效时间",
            trigger: ["blur", "change"],
          },
        ],
      },
      middleButLoading: false,
      outPrint: {},
      middleRegionVos: [],
      middleRowId: "",
      dialogMiddleRegionTitle: "",
      dialogMiddleRegionVisible: false,
      dialogDelVisible: false,
      tableOperation: "",
      detaleData: {
        outSupplierName: "",
        outUserName: "",
        outNo: "",
        outTime: "",
        machines: [],
      }
    };
  },
  created() {
    this.handleCurrentChange();
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams;
    },
  },
  watch: {
    SeachParams(newVal) {
      console.log(newVal);
      this.page.pageNum = 1;
      this.handleCurrentChange();
    },
    "modelform.merchantId"(val) {
      if (!val) {
        this.modelform.companyId = "";
      }
    },
  },
  methods: {
    // 查看示例图大图
    seeSampleImage(row) {
      this.imgList = [];
      this.showViewer = true;
      this.imgList.push(row.selfProof);
    },
    addDialogClose() {
      this.addShow = false
    },
    // 确认添加
    submitAdd() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          console.log(this.rowId, this.formDataDialog);
          http.saveDeliveryType({ outNo: this.rowId, ...this.formDataDialog })
            .then((res) => {
              console.log(res);
              if (res.code === 1) {
                this.$message.success(res.msg);
                this.addShow = false;
                this.handleCurrentChange();
                this.$refs["ruleForm"].resetFields();
                this.addShow = false;
              }
              this.btnLoading = false;
            })
            .catch(() => {
              this.btnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    //删除图片
    handleUploadDelete() {
      this.formDataDialog.selfProof = "";
    },
    // 上传图片后，将图片信息保存到对应参数中
    handleUploadChange(url) {
      this.formDataDialog.selfProof = url;
    },
    // 表格切换页面
    handleCurrentChange(val) {
      if (val) {
        this.pageNum = val;
        this.page.pageNum = val;
      }
      const baseRequest = {
        deliveryType: this.SeachParams.deliveryType,
        endTime: this.SeachParams.endTime,
        outNo: this.SeachParams.outNo,
        outSupplierId: this.SeachParams.outSupplierId,
        userId: this.SeachParams.userId,
        userName: this.SeachParams.userName,
        warehouseId: this.SeachParams.warehouseId,
        startTime: this.SeachParams.startTime,
        outRemark: this.SeachParams.outRemark,
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        merchantId:this.$route.query.merchantId
      };
      this.loading = true;
      http.outwarehouseList(baseRequest).then((res) => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.loading = false;
        }
      }).finally(() => {
          if (typeof this.SeachParams.disuseLoding == "function") {
            this.SeachParams.disuseLoding();
          }
        });
    },
    //打印出库单
    disableopen(row) {
      http.outInfo({ outNo: row.outNo }).then(res => {
        this.detaleData = JSON.parse(JSON.stringify(res.data))
        this.$forceUpdate()
        if (this.detaleData.machines.length > 0) {
          this.$refs.printView.disableopen()
        }
      })
    },
    //出库单详情
    jumDetal(row) {
      let lybl = this.$router.resolve(
        {
          path: '/transferManagement/documentParticulars',
          query: {
            outNo: row.outNo,
            merchantId:this.$route.query.merchantId,
            merchantName:this.$route.query.merchantName
          }
        })

      window.open(lybl.href, '_blank');
    },
    // 关闭弹框关闭
    commandClosed() {
      this.commandShow = false;
      this.command = "";
    },
    // 确认关闭弹框
    submit() {
      if (!this.command) {
        this.$message.error("请输入动态口令");
        return;
      }
      this.butLoading = true;
      let params = {
        id: this.matchId,
        command: this.command,
      };
      _api.closeMatchtransaction(params).then((res) => {
        this.$message.success(res.msg || "操作成功");
        this.butLoading = false;
        this.commandShow = false;
        this.command = "";
        this.handleCurrentChange();
      });
    },
    //
    editBtn(row) {
      this.rowId = row.outNo;
      this.addShow = true;
    },
    // 获取门店商家
    getMatchCompanyList() {
      _api.matchCompanyList({ pageNum: 1, pageSize: 999 }).then((res) => {
        if (res.code === 1) {
          this.alreadyCompanyList = res.data;
        }
      });
    },
    handleDialogClosed() {
      this.$refs["form"].resetFields();
      this.dialogVisible = false;
    },
    // 添加扣款规则
    addRule() {
      this.tableOperation = "add";
      this.middleRowId = "";
      this.dialogMiddleRegionTitle = "添加规则-撮合扣款";
      this.dialogMiddleRegionVisible = true;
    },
    // 扣款条件表格操作
    handleBtn(row, type, index) {
      this.middleRowId = index;
      this.tableOperation = type;
      if (type == "edit") {
        this.dialogMiddleRegionTitle = "编辑规则-撮合扣款";
        this.middleRegion.lower = row.lower;
        this.middleRegion.upper = row.upper;
        this.middleRegion.btstarsTax = row.btstarsTax;
        this.middleRegion.channelTax = row.channelTax;
        this.dialogMiddleRegionVisible = true;
      } else {
        this.dialogDelVisible = true;
      }
    },
    middleRegionDialogClosed() {
      this.middleRegion = {
        lower: "",
        upper: "",
        btstarsTax: "",
        channelTax: "",
      };
      this.$refs["middleRegion"].resetFields();
    },
    // 添加规则-撮合扣款弹框确定
    middleRegHandleSuccess() {
      this.$refs["middleRegion"].validate((valid) => {
        if (valid) {
          let middleRegion = this.middleRegion;
          if (Number(middleRegion.lower) > Number(middleRegion.upper)) {
            this.$message.error("起始值不能大于结束值");
            return;
          }
          if (Number(middleRegion.lower) == Number(middleRegion.upper)) {
            this.$message.error("起始值不能和结束值相等");
            return;
          }
          let params = {
            lower: middleRegion.lower,
            upper: middleRegion.upper,
          };
          if (this.middleRegionVos.length && this.tableOperation != "edit") {
            let numArr = [];
            this.middleRegionVos.forEach((item) => {
              numArr.push(item.lower);
              numArr.push(item.upper);
            });
            let iMin = Math.min(...numArr); //最小值
            let iMAX = Math.max(...numArr); //最大值
            if (middleRegion.upper != iMin && middleRegion.lower != iMAX) {
              this.$message.error("价位区间不连续");
              return;
            }
            if (middleRegion.lower != iMAX && middleRegion.upper != iMin) {
              this.$message.error("价位区间不连续");
              return;
            }
          }
          params.btstarsTax = middleRegion.btstarsTax;
          params.channelTax = middleRegion.channelTax;
          if (this.middleRowId.toString()) {
            this.middleRegionVos.splice(this.middleRowId, 1, params);
          } else {
            this.middleRegionVos.push(params);
          }
          this.dialogMiddleRegionVisible = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 删除弹框确认
    handleSuccessDel() {
      this.middleRegionVos.splice(this.middleRowId, 1);
      this.dialogDelVisible = false;
    },
    commandDialogClosed() {
      this.modelform.command = "";
    },
    //打印单据
    outbleopen(row) {
      this.prnitnum = 1
      this.commandDisableShow = true
      this.outPrint = row
    },
    //导出串号
    Derived(data){
      http.excelImei({outNo:data.outNo,merchantId:this.$route.query.merchantId}).then((res) => {
        console.log(res);
        const content = res;
        const blob = new Blob([content]);

        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          //
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            const fileName = "出库串号明细.xlsx";
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
      });
    },
    // 打印弹框确定
    commandDisableSure() {
      this.commandDisableShow = false
      this.disableopen(this.outPrint)
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.topTitle {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #000000;
}

.foottom {
  margin-top: 10px;
}


.deltyu {
  display: inline-block;
  margin-right: 5px;

  /deep/.el-button {
    color: #e6a23c;
  }
}

.sentyu {
  margin-right: 5px;
  display: inline-block;

  /deep/.el-button {
    color: #67c23a;
  }
}

.GlobalTable {

  // 备注说明
  .Remarks {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; //控制行数
    overflow: hidden;
  }

  .operation-btn {
    /deep/.el-button {
      margin-left: 0;
    }
  }

  .tableBtn {
    color: #0981ff;
    cursor: pointer;
  }

  .enable-tip {
    color: #ff8080;
    p-size: 14px;
    margin-bottom: 20px;

    .tip-sure {
      margin-top: 10px;
      color: #333333;
      p-size: 16px;
      text-align: center;
    }
  }

  .command {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .add-form {
    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }

    /deep/.el-input-group__prepend {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }

    /deep/.el-select {
      width: 100%;
    }
  }

  .rules-form {
    .rule-title {
      display: flex;
      align-items: center;
      color: #ff8080;
      margin-bottom: 20px;
    }

    .tip_ico {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    /deep/.el-input-group__append {
      border: 1px solid #0981ff;
      background: #0981ff;
      color: #ffffff;
    }
  }

  .del-title {
    color: #ff8080;

  }

  .command2 {

    // display: flex;
    // align-items: center;
    .sure_tip {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 0;
      color: #333333;
    }

  }

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .flex1 {
    flex: 1;
  }

  .overflow-hiding {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-all;
    -webkit-line-clamp: 1;
    /*! autoprefixer: off */
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
  }
}
</style>
